import { FC, ReactNode } from 'react'
import SimpleBar from 'simplebar-react'
import clsx from 'clsx'

import 'simplebar-react/dist/simplebar.min.css'
import style from './scroll-area.module.scss'

interface ScrollArea {
  children: ReactNode
  customRef?: ReactNode
  simplebarRef?: any
  modificator?: string
  isGreyScrollbar?: boolean
  isNarrowScrollbar?: boolean
  isHiddenScrollbar?: boolean
}

const ScrollArea: FC<ScrollArea> = ({
  children,
  customRef,
  simplebarRef,
  modificator = '',
  isGreyScrollbar,
  isNarrowScrollbar,
  isHiddenScrollbar,
}: ScrollArea) => {
  return (
    <SimpleBar
      scrollableNodeProps={{ ref: customRef }}
      style={{ maxHeight: '100%' }}
      autoHide={false}
      ref={simplebarRef}
      className={clsx(style.scrollArea, {
        [modificator]: modificator,
        [style.greyScrollbar]: isGreyScrollbar,
        [style.narrowScrollbar]: isNarrowScrollbar,
        [style.hiddenScrollbar]: isHiddenScrollbar,
      })}
    >
      {children}
    </SimpleBar>
  )
}

export default ScrollArea
