import { FC } from 'react'

import WidthLimiter from 'containers/width-limiter/width-limiter'
import IntroPartners from '../intro-partners/intro-partners'
import SpecialityList from '../speciality-list/speciality-list'

import { ISpecialities } from 'store/lists/useSpecialities'

import styles from './intro-bottom-block.module.scss'

interface IIntroBottomBlock {
  specs: ISpecialities[]
}

const IntroBottomBlock: FC<IIntroBottomBlock> = ({ specs }) => {
  return (
    <div className={styles.wrapper}>
      <IntroPartners />
      <WidthLimiter
        noLeftPadding
        noTopPadding
        noRightPadding
        noBottomPadding
        modificator={styles.specialitiesWrapper}
      >
        <SpecialityList specialities={specs} />
      </WidthLimiter>
    </div>
  )
}

export default IntroBottomBlock
