import { STATIC_URL } from 'constants/data'

const cardsData = [
  {
    title: 'Помощь медикам, пострадавшим от наводнения в Оренбургской области',
    description:
      'Более 800 семьям медработников Оренбургской области нужна наша помощь прямо сейчас. У них нет сменной одежды, средств личной гигиены, бытовой химии. У некоторых разрушены дома и требуются деньги на восстановление.',
    href: 'https://vblagodarnost.ru/need-help/item/183-pomoshch-medikam-postradavshim-ot-navodneniya-v-orenburgskoj-oblasti.html',
    imgData: {
      width: 310,
      height: 200,
      src: `${STATIC_URL}/crowd-funding/help-cards/help-card-1.png`,
      alt: 'Наводнение в Оренбургской области',
      unoptimized: true,
    },
  },
  {
    title: 'Помощь фельдшеру Виталию Винокурову из Подмосковья',
    description:
      'У Виталия онкология, впереди трансплантация костного мозга, донором станет родной брат. Во время пересадки потребуется препарат, который убьет раковые клетки, а новым, донорским, поможет прижиться. Стоимость лекарства - 480 000 рублей.',
    href: 'https://vblagodarnost.ru/need-help/item/184-pomoshch-feldsheru-vitaliyu-vinokurovu-iz-podmoskovya.html',
    imgData: {
      width: 310,
      height: 200,
      src: `${STATIC_URL}/crowd-funding/help-cards/help-card-2.png`,
      alt: 'Виталий Винокуров',
      unoptimized: true,
    },
  },
  {
    title: 'Помощь врачу-педиатру Ларисе Юсуповой из Саратовской области',
    description:
      'Ларисе всего 24 года, у нее диагностирован инфаркт головного мозга. У молодого педиатра есть все шансы полностью восстановиться. Для этого необходимо пройти 21-дневный курс реабилитации в центре «Три сестры» стоимостью 561 600 рублей.',
    href: 'https://vblagodarnost.ru/need-help/item/182-pomoshch-vrachu-pediatru-larise-yusupovoj-iz-saratovskoj-oblasti.html',
    imgData: {
      width: 310,
      height: 200,
      src: `${STATIC_URL}/crowd-funding/help-cards/help-card-3.jpeg`,
      alt: 'Лариса Юсупова',
      unoptimized: true,
    },
  },
]

export { cardsData }
