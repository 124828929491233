import clsx from 'clsx'
import Image from 'next/image'
import { useContext, useState } from 'react'
import { useForm, useFormState } from 'react-hook-form'

import ControllerBaseInput from 'components/form-parts/controller-hook-forms/controller-base-input/controller-base-input'
import WidthLimiter from 'containers/width-limiter/width-limiter'
import Button from '../../parts/button/button'
import screenSizeContext from 'context/screenSizeContext'

import A from 'utils/metrics/analytic'
import { A_LETTERSUB_CLICK, A_LETTERSUB_CLICK_SUCCESS } from 'constants/analytic-data'
import { emailValidationNotLK, FieldsNameVoc } from 'constants/auth/profile-data'
import { PROD_STATIC_PATH } from 'constants/data'
import { MediaNames } from 'constants/media-constanst'
import { apiPath } from 'constants/url'
import { postAxiosSingle } from 'pages/api/axios'
import { imgPaths } from './data'

import styles from './own-edition.module.scss'

const OwnEdition = () => {
  const { windowSize } = useContext(screenSizeContext)
  const [statusForm, setStatusForm] = useState('')
  const isMobile = windowSize === MediaNames.MOBILE_SMALL
  const imgData = isMobile ? imgPaths.mobile : imgPaths.desktop
  const isConsultRequestedInMobile = isMobile && Boolean(statusForm)
  const {
    handleSubmit,
    control,
    resetField,
    getValues,
    formState: { dirtyFields },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      [FieldsNameVoc.email]: '',
    },
  })
  const { errors } = useFormState({ control })

  const onSubmitForm = async (data: any) => {
    try {
      if (!getValues()[FieldsNameVoc.email].length) {
        setStatusForm('')
        return null
      }
      const axiosData = await postAxiosSingle(`${apiPath}/users/subscribeDigest`, {}, data)
      A.setEvent(A_LETTERSUB_CLICK_SUCCESS)
      if (axiosData?.response?.status === 400) {
        setStatusForm(axiosData?.response?.data?.status + '😊')
      } else if (axiosData.data) {
        const { status } = axiosData.data
        if (status) {
          resetField(FieldsNameVoc.email)
          setStatusForm('Вы успешно подписались на рассылку от редакции Medpoint 😊')
        } else {
          alert('Что то пошло не так')
          setStatusForm('status')
        }
      }
    } catch (e) {
      console.log('send error')
    }
  }
  const googleEvent = () => {
    A.setEvent(A_LETTERSUB_CLICK)
  }
  const isDisableSubmit = () => {
    if (Object.keys(errors).length === 0) {
      return false
    }
    return true
  }

  return (
    <WidthLimiter noBottomPadding noTopPadding>
      <form id="form" onSubmit={handleSubmit(onSubmitForm)}>
        <div className={styles.title}>Своя редакция</div>
        <div className={styles.edition}>
          <div className={styles.titleCard}>Оставайтесь в курсе событий вместе с Medpoint</div>
          <div className={styles.content}>
            <div
              className={clsx(styles.subtitleCard, {
                [styles.subtitleAfterSub]: isConsultRequestedInMobile,
              })}
            >
              Каждый день публикуем резюме новейших исследований из международных источников
            </div>
            {Boolean(statusForm) && (
              <div
                className={clsx(styles.resultMsg, {
                  [styles.resultAfterSub]: isConsultRequestedInMobile,
                })}
              >
                {statusForm}
              </div>
            )}
            {Boolean(!statusForm) && (
              <>
                <div className={styles.formWrap}>
                  <div className={styles.emailInput} onClick={googleEvent}>
                    <ControllerBaseInput
                      control={control}
                      name={FieldsNameVoc.email}
                      errors={errors}
                      rules={emailValidationNotLK}
                    />
                  </div>
                  <div className={styles.btnWrap}>
                    <Button type="submit" disabled={isDisableSubmit()}>
                      Прислать на почту
                    </Button>
                  </div>
                </div>

                <div className={styles.dopTxt}>
                  Нажимая на кнопку, вы принимаете{' '}
                  <a href={`${PROD_STATIC_PATH}/terms.pdf`} target="_blank" rel="noreferrer">
                    Пользовательское соглашение
                  </a>{' '}
                  и соглашаетесь на обработку{' '}
                  <a href={`${PROD_STATIC_PATH}/agreement.pdf`} target="_blank" rel="noreferrer">
                    персональных данных
                  </a>
                </div>
              </>
            )}
            <div>
              <Image
                className={clsx(styles.img, {
                  [styles.imgBottom]: isConsultRequestedInMobile,
                })}
                unoptimized
                {...imgData}
              />
            </div>
          </div>
        </div>
      </form>
    </WidthLimiter>
  )
}

export default OwnEdition
