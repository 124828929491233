import { ILectur } from '../../../../constants/types/common.types'

const AVATAR_HALF_WIDTH = 25
const SHOWED_LECTORS_AVATARS = 3

const getAllLectorsCount = (Lecturs: ILectur[] | null, lecturName: string | undefined): number => {
  const dopLectursCount = lecturName ? lecturName.split(',').length : 0
  const allLectursCount = Lecturs?.length || 0 + dopLectursCount
  return allLectursCount
}

const cutLecturs = (Lecturs: ILectur[] | null, lecturName: string | undefined) => {
  const allLectursCount = getAllLectorsCount(Lecturs, lecturName)

  const cuttedLecturs =
    allLectursCount > SHOWED_LECTORS_AVATARS
      ? Lecturs?.slice(0, SHOWED_LECTORS_AVATARS).reverse()
      : Lecturs?.reverse()
  return cuttedLecturs
}

const getLectorsIds = (lectorsList: ILectur[]): number[] => lectorsList.map((lector) => lector.id)

const getFullName = (lectorData: ILectur): string => {
  const { firstName, secondName, lastName } = lectorData
  const fullName = `${lastName} ${firstName} ${secondName || ''}`

  return fullName
}

export { AVATAR_HALF_WIDTH, cutLecturs, getAllLectorsCount, getLectorsIds, getFullName }
