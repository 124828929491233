import { FC, useMemo, useContext } from 'react'
import Image from 'next/image'
import Link from 'next/link'

import Lectors from 'components/cards/new-webinar-card/lectors/lectors'
import ToggleButton from 'components/parts/toggle-button/toggle-button'
import CardSpeciality from 'components/cards/card-parts/card-speciality/card-speciality'
import screenSizeContext from 'context/screenSizeContext'

import { formatMoscowDate } from 'utils/formatDate'
import { IWebinarCard2 } from 'constants/types/card.types'
import { cutTxt } from 'utils/cutTxt'
import { Pathname } from 'constants/data'
import { imgData } from './data'
import { MediaNames } from 'constants/media-constanst'

import styles from './cards-webinars.module.scss'

const CardsWebinars: FC<IWebinarCard2> = ({
  name,
  startDate,
  Lecturs,
  activeDots,
  Specialities,
  lecturName,
  linkTranslation,
  id,
}: IWebinarCard2) => {
  const url = `/${Pathname.WEBINAR}/${linkTranslation || id}`
  const { windowSize } = useContext(screenSizeContext)
  const isMobile = windowSize === MediaNames.MOBILE_SMALL

  const formatedDate: any = useMemo(
    () => (startDate ? formatMoscowDate(startDate as Date) : {}),
    [startDate]
  )

  return (
    <div className={styles.card}>
      <div className={styles.leftSide}>
        <div className={styles.top}>
          <div
            className={styles.date}
          >{`${formatedDate.date} ${formatedDate.monthName}, ${formatedDate.time} МСК`}</div>

          {Specialities && (
            <div className={styles.speciality}>
              <CardSpeciality Specialities={Specialities} />
            </div>
          )}
        </div>
        <div>
          <p className={styles.title}>{isMobile ? cutTxt(name, 77) : cutTxt(name, 97)}</p>

          <div className={styles.lectur}>
            <Lectors Lecturs={Lecturs} lecturName={lecturName} />
          </div>

          <div className={styles.btnWrap}>
            <Link href={url}>
              <ToggleButton>
                <span>Подробнее</span>
              </ToggleButton>
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.rightSide}>{<Image {...imgData[activeDots || 0]} />}</div>
    </div>
  )
}

export default CardsWebinars
