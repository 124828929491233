import Image from 'next/image'
import Link from 'next/link'

import usePCWindow from 'hooks/usePCWindow'
import ScrollArea from 'containers/scroll-area/scroll-area'

import { cardsData } from './data'
import { ScreenSize } from 'constants/media-constanst'

import styles from './crowd-help.module.scss'

const CardsComponent = ({ item, index, isLink }: any) => {
  return (
    <div className={isLink ? styles.link : styles.noLink}>
      <Link href={item.href} target="_blank">
        <div className={styles.crowdHelpCard} key={index}>
          <div className={styles.imageWrapper}>
            <Image className={styles.image} {...item.imgData} />
          </div>
          <div>
            <div className={styles.title}>{item?.title}</div>
            <div className={styles.description}>{item?.description}</div>
          </div>
        </div>
      </Link>
    </div>
  )
}

interface ICrowdHelp {
  isLink: boolean
}

const CrowdHelp = ({ isLink }: ICrowdHelp) => {
  const isBigPc = usePCWindow(ScreenSize.big)

  return (
    <div className={styles.crowdHelpCards}>
      {cardsData.map((item, index) => {
        return <CardsComponent item={item} index={index} key={index} isLink={isLink} />
      })}
    </div>
  )
}

export default CrowdHelp
