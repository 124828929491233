const cutTxt = (txt: string, endTxt: number) => {
  const allSymbols = txt.length

  if (allSymbols <= endTxt) {
    return txt
  } else {
    return `${txt.slice(0, endTxt)} ...`
  }
}

export { cutTxt }
