import { FC } from 'react'
import Image from 'next/legacy/image'
import clsx from 'clsx'

import styles from './partner-card.module.scss'

interface IPartnerCard {
  logo: string
  logoWidth: number
  alt: string
  title: string | React.ReactNode
  text: string
  modificator?: string
}

const PartnerCard: FC<IPartnerCard> = ({
  logo,
  alt,
  title,
  text,
  logoWidth,
  modificator,
}: IPartnerCard) => (
  <div className={clsx(styles.card, { [modificator as string]: modificator })}>
    <div className={styles.iconWrapper}>
      <Image src={logo} alt={alt} width={logoWidth} height={56} />
    </div>
    <h3 className={styles.title}>{title}</h3>
    <p className={styles.text} dangerouslySetInnerHTML={{ __html: text }}></p>
  </div>
)

export default PartnerCard
