import Link from 'next/link'

import WidthLimiter from 'containers/width-limiter/width-limiter'
import PartnerCard from 'components/cards/partner-card/partner-card'

import { STATIC_URL } from 'constants/data'

import styles from './intro-partners.module.scss'

const imgPath = {
  logo_ragg: `${STATIC_URL}/partners/logo_ragg.svg`,
  logo_rgnk: `${STATIC_URL}/partners/logo_rgnk.svg`,
  logo_rnimu: `${STATIC_URL}/partners/logo_rnimu.svg`,
  logo_polisan: `${STATIC_URL}/partners/logo_polisan.png`,
  logo_berlin_himi: `${STATIC_URL}/partners/logo_berlin_himi.png`,
  logo_r_pharm: `${STATIC_URL}/partners/logo_r_pharm.png`,
  logo_servier: `${STATIC_URL}/partners/logo_servier.png`,
}

const partnerDatasets = [
  {
    href: 'https://rsmu.ru/',
    logo: imgPath.logo_rnimu,
    logoWidth: 42,
    title: <>РНИМУ имени Н.И. Пирогова</>,
    text: 'Ведущий научно-образовательный медицинский центр, обеспечивающий подготовку врачей, фармацевтов, клинических психологов, социальных работников, а также уникальных медицинских научных кадров.',
    alt: 'rnimu icon',
  },
  {
    href: 'https://rosagg.ru/',
    logo: imgPath.logo_ragg,
    logoWidth: 31,
    title: <>Российская Ассоциация Геронтологов&nbsp;и Гериатров</>,
    text: 'Цель Ассоциации - содействие достижению здорового, активного и трудоспособного долголетия граждан России, социальной защите людей пожилого и старческого возраста.',
    alt: 'rosagg icon',
  },
  {
    href: 'http://rgnkc.ru/',
    logo: imgPath.logo_rgnk,
    logoWidth: 30,
    title: <>Российский геронтологический научно-клинический центр</>,
    text: 'Центр специализируется на лечении пожилых людей, но медицинскую помощь здесь может получить пациент любого возраста.',
    alt: 'rgnkc icon',
  },
]

const IntroPartners = () => {
  return (
    <WidthLimiter noBottomPadding noTopPadding>
      <h2 className={styles.title}>Наши партнеры</h2>
      <div className={styles.cardWrapper}>
        {partnerDatasets.map(({ href, ...item }) => (
          <Link href={href} key={href} className={styles.card} target="_blank">
            <PartnerCard {...item} modificator={styles.modificator} />
          </Link>
        ))}
      </div>
    </WidthLimiter>
  )
}

export default IntroPartners
