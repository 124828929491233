import clsx from 'clsx'
import Link from 'next/link'
import { FC, useState } from 'react'

import { ISpecialities } from 'store/lists/useSpecialities'

import styles from './speciality-list.module.scss'

const convertName = (str = '') => str.replace(/\s+/g, '-').toLocaleLowerCase()
const SHORT_SPEC_COUNT = 10
const showBtnData = { label: 'Показать все', value: 0, labelEng: '' }
const hideBtnData = { label: 'Скрыть', value: 0, labelEng: '' }

interface ISpecialityList {
  specialities: ISpecialities[]
}

const SpecialityList: FC<ISpecialityList> = ({ specialities }) => {
  const initSpecs = [...specialities.slice(0, SHORT_SPEC_COUNT), showBtnData]
  const fullSpecs = [...specialities, hideBtnData]
  const [specs, setSpecs] = useState(initSpecs)

  const toggleSpecs = () => {
    const updatedSpecs = specs.length <= SHORT_SPEC_COUNT + 1 ? fullSpecs : initSpecs
    setSpecs(updatedSpecs)
  }

  return (
    <div>
      <div className={styles.title}>
        Учеба с Medpoint — это возможность в удобное для вас время изучить все необходимые материалы
        в личном кабинете НМО и получить удостоверение о повышении квалификации. У нас вы сможете
        найти вебинары с НМО, медицинские статьи, клинические случаи и программы повышения
        квалификации непрерывного медицинского обучения по своей специальности.
      </div>
      <div className={styles.specListWrapper}>
        {specs?.map((item, index, array) => {
          if (index === array.length - 1) {
            return (
              <div
                onClick={toggleSpecs}
                className={clsx(styles.speciality, styles.btn)}
                key={item.label}
              >
                {item.label}
              </div>
            )
          } else {
            const partOfLink = convertName(item.labelEng)

            return (
              <Link href={`/speciality/${partOfLink}-${item.value}`} key={item.label}>
                <div className={styles.speciality}>{item.label}</div>
              </Link>
            )
          }
        })}
      </div>
    </div>
  )
}

export default SpecialityList
