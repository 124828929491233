import { FC } from 'react'
import Skeleton from 'react-loading-skeleton'

import Auth from 'store/auth'

import { defineSelectedForCard } from 'utils/search/defineCardSelected'
import { ISpeciality } from 'constants/types/common.types'

interface ICardSpeciality {
  Specialities: ISpeciality[]
}

const defineSpecialityForCard = (
  userSpecialities: ISpeciality[] = [],
  Specialities?: ISpeciality[]
) => {
  const cardSpecs = (Specialities as ISpeciality[])?.map((spec) => spec.name)
  return defineSelectedForCard(cardSpecs, userSpecialities)
}

const CardSpeciality: FC<ICardSpeciality> = ({ Specialities }: ICardSpeciality) => {
  const { anketaProfile } = Auth.useAuth()

  return (
    defineSpecialityForCard(anketaProfile.Specialities, Specialities) || (
      <Skeleton width={150} height={20} />
    )
  )
}

export default CardSpeciality
