import clsx from 'clsx'

import WidthLimiter from 'containers/width-limiter/width-limiter'
import ScrollArea from 'containers/scroll-area/scroll-area'

import { STATIC_URL } from 'constants/data'

import styles from './why-us.module.scss'

const imgPath = {
  card1: `${STATIC_URL}/intro-alt/card-1-us.png`,
  card2: `${STATIC_URL}/intro-alt/card-2-us.png`,
  card3: `${STATIC_URL}/intro-alt/card-3-us.png`,
  card4: `${STATIC_URL}/intro-alt/card-4-us.png`,
}

const dataTxt = [
  {
    title: 'Баллы НМО <b>в день вебинара</b>',
    class: styles.card1,
    bg: imgPath.card1,
  },
  {
    title: '<b>Удобный личный кабинет</b> с историей набора баллов НМО',
    class: styles.card2,
    bg: imgPath.card2,
  },
  {
    title: '<b>Курсы НМО</b> от ведущих специалистов',
    class: styles.card3,
    bg: imgPath.card3,
  },
  {
    title: 'Разборы сложных тем <b>в прямом эфире</b>',
    class: styles.card4,
    bg: imgPath.card4,
  },
]

const WhyUs = () => {
  return (
    <WidthLimiter noBottomPadding noTopPadding>
      <div className={styles.title}>Почему нас выбирают</div>
      <ScrollArea isHiddenScrollbar modificator={styles.scrollWrap}>
        <div className={styles.cardsWrap}>
          {dataTxt.map((item, index) => {
            return (
              <div
                className={clsx(item.class, styles.card)}
                style={{ backgroundImage: `url(${item.bg})` }}
                key={index}
              >
                <div className={styles.txt} dangerouslySetInnerHTML={{ __html: item.title }}></div>
              </div>
            )
          })}
        </div>
      </ScrollArea>
    </WidthLimiter>
  )
}

export default WhyUs
